<template>
  <div class="slot-car-races">
    <component :is="component" />
  </div>
</template>

<script>
import dynamicImport from '@/mixins/dynamicImport';

export default {
  name: 'SlotCarRaces',
  mixins: [dynamicImport],
};
</script>
