export default {
  props: [
    'path',
  ],
  data() {
    return {
      component: null,
    };
  },
  computed: {
    loader() {
      if (!this.path) {
        return null;
      }
      return () => import(/* webpackChunkName: "dynamicImport" */ '@/' + this.path);
    },
  },
  mounted() {
    this.loader()
      .then(() => {
        this.component = () => this.loader();
      })
      .catch((e) => {
        throw new Error(e);
      });
  },
};
