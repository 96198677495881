<template>
  <div class="home">
    <component :is="gameComponent" :path="`layouts/${gameLayout}`"/>
  </div>
</template>

<script>
import { upperFirst } from 'lodash';
import { mapGetters } from 'vuex';
import GreyhoundRaces from './GreyhoundRaces';
import SlotCarRaces from './SlotCarRaces';
import VirtualGreyhoundRaces from './VirtualGreyhoundRaces';
import VirtualHorseRaces from './VirtualHorseRaces';
import VirtualMotorcycleSpeedway from './VirtualMotorcycleSpeedway';

export default {
  name: 'Home',
  components: {
    GreyhoundRaces,
    SlotCarRaces,
    VirtualGreyhoundRaces,
    VirtualHorseRaces,
    VirtualMotorcycleSpeedway,
  },
  computed: {
    ...mapGetters([
      'activeProductName',
      'config',
      'appLayout',
    ]),
    gameComponent() {
      return upperFirst(this.activeProductName);
    },
    gameLayout() {
      return this.config.appLayout;
    },
  },
};
</script>
