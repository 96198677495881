<template>
  <div class="virtual-greyhound-races">
    <component :is="component" />
  </div>
</template>

<script>
import dynamicImport from '@/mixins/dynamicImport';

export default {
  name: 'VirtualGreyhoundRaces',
  mixins: [dynamicImport],
};
</script>
