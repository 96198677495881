<template>
  <div class="virtual-motorcycle-speedway">
    <component :is="component" />
  </div>
</template>

<script>
import dynamicImport from '@/mixins/dynamicImport';

export default {
  name: 'VirtualMotorcycleSpeedway',
  mixins: [dynamicImport],
};
</script>
